@import "styles/variaveis.scss";

.lista-home {
  padding-left: 0;
  list-style-position: inside;

  li {
    list-style-type: none;
  }

  li:before {
    content: "•";
    color: $corAzulPortal;
    font-size: 35px;
    margin-right: 10px;
    vertical-align: middle;
    line-height: 1.18em;
  }
}

.links-intrucoes {
  color: $corCinzaRodape;
}

.container-video-home {
  margin-top: 4em;
}

.oferta-imoveis {
  @media screen and (min-width: 992px) {
    img.desenho-fornecedor {
      display: none !important;
    }
  }
  @media screen and (max-width: 991px) {
    background: linear-gradient(
      179.86deg,
      #c5e178 60.58%,
      rgba(26, 110, 184, 0) 99.93%
    ) !important;
    h1 {
      position: relative;
      font-size: 28px !important;
    }
    img.desenho-criancas {
      padding-right: 15px;
    }
    .saiba-mais {
      display: block;
      text-align: center;
      margin-top: 2em;
    }
  }
}
