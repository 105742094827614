@import "styles/variaveis.scss";

div.input {
  &.autocomplete {
    label {
      font-size: 11.5px;
      color: $corAzulPortal;
    }
    input {
      border-radius: 30px;
    }
  }
  font-size: 14px;
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
    padding-top: 7px;
  }
  label {
    font-weight: 500;
    color: $gray;
  }
  input {
    font-size: 14px;
    color: $blackInput;
    height: 38px;
    font-weight: normal;
    &:focus {
      color: $blackInput;
    }
    &::placeholder {
      color: $grayPlaceholder;
    }
    &.invalid-field:not(:focus):invalid {
      box-shadow: none !important;
      border-color: $red !important;
    }
    &:disabled {
      background-color: $grayDisabled;
    }
  }
}

.invalid-field {
  box-shadow: none !important;
  border-color: $red;
}
