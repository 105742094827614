.banner-consulta-cadastro{
  background: #196CBD;
  height: 132px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .titulo-banner{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .botao-container{
    margin: 0 auto;
  }

  .botao-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    appearance: button;
    text-decoration: none;

    background: #BD2319;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}

