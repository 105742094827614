@import "styles/variaveis.scss";

.uniforme-escolar {
  @media screen and (min-width: 992px) {
    background: url("../../img/desenho-alunos.png"), #c4c4c4;
    background-position: right;
    background-repeat: no-repeat;
    background-color: #daeeff;
    img.desenho-criancas {
      display: none !important;
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: 1em;
    img.desenho-criancas {
      padding-right: 15px;
    }
    h1 {
      position: relative;
      font-size: 28px !important;
    }
    background: linear-gradient(
      179.86deg,
      #daeeff 60.58%,
      rgba(26, 110, 184, 0) 99.93%
    );
  }
  color: $corPretoPortal;
  height: 490px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;

  .conteudo {
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    h1 {
      font-size: 40px;
      font-weight: bold;
    }
    .titulo-lista {
      font-size: 20px;
      line-height: 26px;
      font-style: normal;
      font-weight: bold;
    }
    .lista{
      margin-bottom: 0.5rem;
    }

  }
}

@media screen and (max-width: 575px) {
  .area-fabricantes {
    margin-top: 3em;
  }
}

@media screen and (max-width: 991px) {
  .conteudo {
    top: 85% !important;
  }
  .container-menu {
    margin-top: 35% !important;
  }
}

@media screen and (max-width: 575px) {
  .container-menu {
    margin-top: 55% !important;
  }
}
