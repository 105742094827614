@import "styles/variaveis.scss";

.login-bg {
  background: url("../../img/login_bg.png");
  width: 50%;
  position: absolute;
  height: 100%;
  left: 0px;
  object-fit: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-half {
  background-color: #fffffd;
  position: absolute;
  right: 0px;
  width: 50%;
  height: 100%;
  text-align: center;
  .container {
    position: absolute;
    left: 50%;
    height: 100%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0 85px;
    text-align: left;
    div.form {
      .login {
        div[class*="col-"] {
          padding: 0;
        }
      }
    }
    .logo-sigpae {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      @media screen and (max-height: 610px) {
        padding-top: 2rem;
        img {
          height: auto;
          width: 300px;
        }
      }
      @media screen and (min-height: 611px) {
        padding-top: 2rem;
        padding-bottom: 1rem;
        img {
          height: 105px;
          width: 427px;
        }
      }
    }
    .terms {
      font-size: 12px;
      color: $blue;
    }
    span.text-terms {
      padding-left: 2rem;
    }
    .logo-prefeitura {
      text-align: center;
      position: absolute;
      left: 37%;
      bottom: 2em;
      @media screen and (max-height: 610px) {
        padding-top: 1rem;
        img {
          height: auto;
          width: 100px;
        }
      }
      @media screen and (min-height: 611px) {
        padding-top: 2rem;
        img {
          height: 65px;
          width: 171px;
        }
      }
    }
  }
}
