@import "../../styles/variaveis.scss";

div.desenvolvimento-escolar {
  .container {
    div.tabs {
      font-weight: bold;
      text-align: center;
      font-size: 20px;
      cursor: pointer;
      margin-left: 2em;
      margin-right: 1em;
      .tab {
        padding-bottom: 5px;
        margin-left: -8px;
        &.active {
          color: $corAzulPortal;
          border-bottom: 3px solid $corAzulPortal;
        }
        &.inactive {
          color: $grayDisabled;
          border-bottom: 3px solid $grayDisabled;
        }
        &.enabled {
          color: $blueEnabled;
          border-bottom: 3px solid $blueEnabled;
        }
      }
    }
    .card {
      .card-body {
        .card-title {
          font-size: 20px;
          font-weight: bold;
        }
        .undertitle {
          font-size: 16px;
          font-weight: bold;
        } 
      }
    }
  }
}

div.file-existent {
  div.label {
    font-size: 14px;
    color: $gray;
  }
  div.success-message {
    color: $corVerdeEscuro;
    font-weight: bold;
  }
  span.delete {
    margin-left: 2em;
    font-weight: bold;
    background-color: $corAzulFiltro;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: $corAzulPortal;
    }
  }
}

div.no-file-end-signup {
  div.label {
    font-size: 14px;
    color: $gray;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.red-word {
  color: $red;
}

.blink {
  color: $red;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.set-opacity {
  opacity: 0.5;
}
