@import "styles/variaveis.scss";

.lojas-credenciadas {
  h1 {
    color: $blue;
    font-weight: bold;
  }
  h2 {
    color: $blue;
    font-weight: bold;
    font-size: 16px;
  }
  .loja-dados {
    font-size: 14px;
    .nome-loja {
      font-size: 16px;
    }
    img {
      height: 100px;
    }
  }
}
