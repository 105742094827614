@import "../../styles/variaveis.scss";

.profile-main-loader {
  left: 50% !important;
  margin-left: -50px;
  position: fixed !important;
  top: 50% !important;
  margin-top: -100px;
  width: 45px;
  z-index: 9000 !important;
  .loader {
    position: relative;
    margin: 0px auto;
    width: 100px;
    height: 100px;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  span {
    //padding-left: 3.5em;
  }
}

.profile-main-loader .loader .circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: $corAzulPortal;
  }
  40% {
    stroke: $corAzulPortal;
  }
  66% {
    stroke: $corAzulPortal;
  }
  80%,
  90% {
    stroke: $corAzulPortal;
  }
}
@keyframes color {
  0% {
    stroke: $corAzulPortal;
  }
  40% {
    stroke: $corAzulPortal;
  }
  66% {
    stroke: $corAzulPortal;
  }
  80%,
  90% {
    stroke: $corAzulPortal;
  }
}
