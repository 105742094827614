.opaco {
  opacity: 0.5;
}

.lojas {
  padding-top: 2em;
  @media (min-width: 992px) {
    min-height: 860px;
  }
  .tabela-lojas {
    font-size: 14px;
    button.btn-outline-primary {
      border-radius: 30px;
    }
    div.row {
      padding-top: 10px;
      padding-bottom: 10px;
      &.tabela-header {
        border-bottom: 1px solid black;
        font-weight: bold;
      }
    }
    .loja-collapse {
      .row.td {
        border-bottom: 1px solid #dbdbdb;
        &:hover {
          background: #f6fbff;
        }
      }
      i {
        cursor: pointer;
        color: #00a6e2;
      }
      .clique-mensagem {
        font-size: 11px;
      }
      .badges {
        @media screen and (max-width: 991px) {
          margin-left: 2em;
        }
        @media screen and (min-width: 992px) {
          text-align: right;
        }
        span.badge-fornecimento {
          padding: 3px 10px;
          font-weight: normal;
          font-size: 12px;
          background: #f5f6f8;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
      table.tabela-precos {
        margin-right: 2.5em;
        width: 100%;
        thead {
          tr {
            background-color: #f5f6f8;
            th {
              padding: 10px;
            }
          }
        }
        tbody {
          tr {
            background-color: white;
            td {
              border: 1px solid #e0e0e0;
              padding: 10px;
            }
          }
          .valor-total {
            font-weight: bold;
          }
        }
      }
    }
  }
}
