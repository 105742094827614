@import "../../styles/variaveis.scss";

div.select {
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }
  label {
    font-size: 14px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    font-size: 14px;
    height: 38px;
    color: $blackInput;
    &:disabled {
      background-color: $grayDisabled;
    }
    &:focus {
      color: $blackInput;
    }
    &.invalid-field:not(:focus):invalid {
      box-shadow: none;
      border-color: $red;
    }
  }
}
